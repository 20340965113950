import request from '@/utils/request'

// 用户总数 会员总数 累计消费金额 累计消费人数 月度消费人次 日均消费人次
export const getAreaAll = (data) =>
  request({
    url: '/v1/screen/statistics/all',
    method: 'get',
    params: data,
  })

// 获取区域内机构综合体
export const getAreaList = (data) =>
  request({
    url: '/v1/screen/area/ins',
    method: 'get',
    params: data,
  })

// 获取实时订单列表
export const getServiceList = (code) =>
  request({
    url: '/v1/screen/gov/order/top?districtCode=' + code,
    method: 'get',
  })

//获取食品留样记录
export const getFood = (insNo) =>
  request({
    url: '/v1/screen/ins/kitchenFoodSample?insNo=' + insNo,
    method: 'get',
  })

//获取安全信息
export const getSecurityInformation = (insNo) =>
  request({
    url: '/v1/screen/ins/securityInformation?insNo=' + insNo,
    method: 'get',
  })

// 安全异常数据+政府采购居家养老服务数据
export const getSafetyLiving = (code) =>
  request({
    url: '/v1/screen/statistics/safety_living',
    method: 'get',
    params: {
      code,
    },
  })

// 获取订单列表
export const getOrderList = (data) =>
  request({
    url: '/v1/screen/gov/order-page',
    method: 'post',
    data: data,
  })

// 获取订单详情
export const getOrderDetail = (orderNo) =>
  request({
    url: '/v1/screen/gov/order-detail?orderNo=' + orderNo,
    method: 'get',
  })
// 获取订单政府补助
export const getOrderinfo = (code, data) =>
  request({
    url: `/v1/screen/gov/contract/screen/customer_subsidy_info?contractGlobalId=${code}&customerGlobalId=${data}`,
    method: 'get',
  })
// 获取合同统计数据
export const getContractMessage = (data) =>
  request({
    url: '/v1/screen/gov/contract/statistics',
    method: 'post',
    data,
  })

// 获取合同列表
export const getContractList = (data) =>
  request({
    url: '/v1/screen/gov/contract/all',
    method: 'post',
    data,
  })

// 获取区域下的村镇
export const getAreaLists = (code) =>
  request({
    url: 'ums/v1/area/sub_list?code=' + code,
    method: 'get',
    type: 'ums',
  })
